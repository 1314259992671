import Api from "../Api";
import Settings from "./Settings";
import i18n from "../../i18n";
import { couponMixin } from "@/mixins/chequemotivaCoupons";

function mapCouponToProductFormat(coupon, couponsPointValue) {
  return {
      id: coupon.id,
      name: coupon.name,
      sku: coupon.sku,
      stock: 100, // Always in stock if enabled
      type: 1, // Digital type
      brand: coupon.brand,
      description: coupon.description || '',
      value: coupon.price / couponsPointValue,
      images: [coupon.thumbnail], 
      visible: 1,
      category_id: coupon.category,
      options: { attributes: null },
      isCoupon: true,
      couponData: coupon
  };
}

export default {
  async allProducts() {
    try {
      const fetchProductsUrl =
        i18n.global.locale === "en"
          ? `front-catalog/${i18n.global.locale}/products`
          : "front-catalog/products";

      // Fetch products from the API
      let fetchProducts = await Api.get(fetchProductsUrl);

      const settings = await Settings.allSettings().then((response) => response.data);

      if (settings.coupons_enabled) {
        // Access coupons from the mixin
        const coupons = couponMixin.data().coupons;
        
        // Map and merge the coupons into the product list
        const mappedCoupons = coupons.map((coupon) => mapCouponToProductFormat(coupon, settings.coupons_point_value));
        fetchProducts.data = fetchProducts.data.concat(mappedCoupons);
      }
      
      return fetchProducts;
    } catch (error) {
      console.error("Error fetching products:", error);
      throw error; // Optionally propagate the error for further handling
    }
  },
};
