import axios from "axios";
import config from "../../public/config.json"
import router from "../../src/router";
import store from "../../src/store";

const ApiOmniwallet = axios.create({
    withCredentials: true,
    timeout: 30000,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`
    }
});

ApiOmniwallet.interceptors.request.use(function (axiosConfig) {
    if (config.api.dev_mode === false) {
        axiosConfig.baseURL = `https://dashboard.${window.location.host.split('.')[1]}.net`; // We use the catalog domain to decide wether to call the production or staging backend
        axiosConfig.headers.Authorization = `Bearer ${localStorage.getItem("omniwalletTemporaryToken")}`; // Used for automatic user login in some integrations
        const subdomain = window.location.host.split('.')[0]
        axiosConfig.headers['X-Omniwallet-Account'] = subdomain // The catalog subdomain is used to identify the Omniwallet account
    } else {
        axiosConfig.baseURL = config.api.url;
        axiosConfig.headers['X-Omniwallet-Account'] = config.api.dev_header;
        axiosConfig.headers.Authorization = `Bearer ${config.api.dev_token}`;
    }

    // For local testing (TODO: should check node env prod or dev instead)
    //const hostname = 'tenant.base_omniwallet.test';
    //config.baseURL = `https://${hostname}`;

    return axiosConfig;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

ApiOmniwallet.interceptors.response.use((response) => {
    // Do nothing
    return response
  }, (error) => {
    // Extra measure to redirect user to login in case session gets invalidated in server
    // but local state (stored in Pinia store) thinks the user is still logged in
    logoutIfUnauthorized(error)

    return Promise.reject(error)
  })

async function logoutIfUnauthorized(error) {
    // Unauthorized
    if (error.response.status === 401) {
        // Update auth store using logout action
        store._actions['auth/logout'][0]()
        router.push({ name: "Login" });
    }
}

export default ApiOmniwallet;
